export class StringUtils {
  static replaceAll(text: string, what: string, replace: string): string {
    if (!text) {
      return text;
    }

    return text.replace(new RegExp(what.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&'), 'g'), replace);
  }

  static isUserBookDraftsUrl(url: string): boolean {
    return url && url.includes('/UserBookDrafts/');
  }

  static isSecuredImages(url: string): boolean {
    const lcUrl = url.toLowerCase();
    return lcUrl.includes('/userbookdrafts/') || lcUrl.includes('/customcontents/');
  }

  static hexToRgb(hex: string) {
    const rgb = hex.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i
      , (m, r, g, b) => '#' + r + r + g + g + b + b)
      .substring(1).match(/.{2}/g)
      .map(x => parseInt(x, 16));
    return {r: rgb[0], g: rgb[1], b: rgb[2]};
  }

  static encodeRFC3986URIComponent(str) {
    return encodeURIComponent(str).replace(
      /[!'()*]/g,
      (c) => `%${c.charCodeAt(0).toString(16).toUpperCase()}`,
    );
  }

  static equalsIgnoringCase(a: string | number, b: string | number) {
    if (typeof a !== 'string' || typeof b !== 'string') {
      return a === b;
    }
    return a.localeCompare(b, undefined, { sensitivity: 'base' }) === 0;
  }

  static parseJwt(token: any) {
    let base64Url = token.split('.')[1];
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    let jsonPayload = decodeURIComponent(window.atob(base64).split('').map((c) => {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  }

  static generateGUID(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16 | 0,
        v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  static transformLanguageCodeToString(lang: string) {
    if (lang === 'en') {
      return 'English';
    } else if (lang === 'es') {
      return 'Spanish';
    } else {
      return lang;
    }
  }
}
